@use "@angular/material" as mat;

//material component overrides
@mixin mat-overrides {
  // Button
  @include mat.button-overrides((container-shape: 5px,
      outline-color: var(--sys-outline-variant),
    ));

  @include mat.form-field-overrides((outline-color: var(--sys-outline-variant),
    ));

  // Button Toggle
  @include mat.button-toggle-overrides((shape: 5px,
      selected-state-background-color: var(--sys-secondary-container),
    ));

  .mdc-button {
    min-width: 112px !important;
    min-height: 44px !important;
  }



  // Divider
  @include mat.divider-overrides((color: var(--sys-outline-variant),
      width: 1px,
    ));





  @include mat.select-overrides((panel-background-color: var(--sys-surface-container-lowest),
    ));
}

// Color overrides
@mixin light-theme-palette-overrides {
  --sys-surface-container-lowest: #ffffff;
  --sys-surface-container-low: #f4f3fa;
  --sys-surface-container: #efedf4;
  --sys-surface-container-high: #e9e7ef;
  --sys-surface-container-highest: #e3e1e9;
}

@mixin dark-theme-palette-overrides {
  --sys-surface-container-lowest: #0d0e13;
  --sys-surface-container-low: #1a1b21;
  --sys-surface-container: #1e1f25;
  --sys-surface-container-high: #292a2f;
  --sys-surface-container-highest: #32343e;
  --sys-on-surface-variant: #C6C5D0;
  --sys-error-container: #690005;
}

// Typography overrides
@mixin typography-overrides {
  /* Display */
  --sys-display-small-tracking: 0;
  --sys-display-medium-tracking: 0;
  --sys-display-large-tracking: 0;

  /* Headline */
  --sys-headline-small-tracking: 0;
  --sys-headline-medium-tracking: 0;
  --sys-headline-large-tracking: 0;

  .mat-headline-small {
    margin: 0px !important;
  }

  .mat-headline-large {
    margin: 0px !important;
  }

  /* Title */
  --sys-title-small-tracking: 0;
  --sys-title-medium-tracking: 0;
  --sys-title-large-tracking: 0;

  /* Body */
  --sys-body-small-tracking: 0;
  --sys-body-medium-tracking: 0;
  --sys-body-large-tracking: 0;

  /* Label */
  --sys-label-small-tracking: 0;
  --sys-label-medium-tracking: 0;
  --sys-label-large-tracking: 0;

  --sys-title-medium: 600 1rem / 1.5rem General Sans;
}