/* ---------- BASIC CONFIGURATION ---------- */

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,600&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  overflow: hidden;
  height: 100%;
}


a {
  cursor: pointer !important;
  text-decoration: none !important;
}
::-webkit-scrollbar {
  display: none;
}

/* for removing scroll buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Add this class to avoid vertical line in mat-form-field  */
.mdc-notched-outline__notch {
  border-right: none !important;
}



@media (max-width: 480px) {
  .container {
    /* Important for Responsiveness please DO NOT remove this */
     position: fixed;
     inset: 0;
     overflow: auto;
     display: grid;
  }
}

@media (min-width: 481px) {
  .container {
    max-width: 360px !important;
    border-width: 1px !important;
    border-color: var(--sys-outline-variant);
    min-height: 640px; /* Minimum height of 640px for larger screens */
  }
}