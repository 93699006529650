@use "sass:map";
@use "@angular/material" as mat;

// Extended Palette Seed Colors
$extended-palette-seed-colors: (
  warn: #ffc107,
  success: #4caf50,
  financial-red: #e53935,
  financial-green: #357e38,
  elite: #1c1c9e,
  itd-blue: #124174,
  meet-blue: #0062ab,
  meet-red: #d32f2f,
);

// Extended Palettes
$extended-palettes: (
  warn: (
    palette-light: #785900,
    on-palette-light: #ffffff,
    palette-container-light: #ffefd4,
    on-palette-container-light: #261a00,
    palette-dark: #fabd00,
    on-palette-dark: #3f2e00,
    palette-container-dark: #5b4300,
    on-palette-container-dark: #ffefd4
  ),
  success: (
    palette-light: #006e1c,
    on-palette-light: #ffffff,
    palette-container-light: #ecffe4,
    on-palette-container-light: #002204,
    palette-dark: #78dc77,
    on-palette-dark: #00390a,
    palette-container-dark: #005313,
    on-palette-container-dark: #ecffe4
  ),
  financial-green: (
    palette-light: #226c29,
    on-palette-light: #ffffff,
    palette-container-light: #ecffe4,
    on-palette-container-light: #002204,
    palette-dark: #8cd987,
    on-palette-dark: #00390a,
    palette-container-dark: #005313,
    on-palette-container-dark: #ecffe4
  ),
  financial-red: (
    palette-light: #bb171c,
    on-palette-light: #ffffff,
    palette-container-light: #ffedea,
    on-palette-container-light: #410002,
    palette-dark: #ffb4ac,
    on-palette-dark: #690006,
    palette-container-dark: #93000d,
    on-palette-container-dark: #ffedea
  ),
  elite: (
    palette-light: #001371,
    on-palette-light: #ffffff,
    palette-container-light: #f0efff,
    on-palette-container-light: #000e5e,
    palette-dark: #bbc3ff,
    on-palette-dark: #15257e,
    palette-container-dark: #303e95,
    on-palette-container-dark: #f0efff
  ),
  itd-blue: (
    palette-light: #124174,
    on-palette-light: #ffffff,
    palette-container-light: #ebf1ff,
    on-palette-container-light: #001c3a,
    palette-dark: #a5c8ff,
    on-palette-dark: #00315e,
    palette-container-dark: #004786,
    on-palette-container-dark: #ebf1ff
  ),
  meet-blue: (
    palette-light: #084898,
    on-palette-light: #ffffff,
    palette-container-light: #edf0ff,
    on-palette-container-light: #001a41,
    palette-dark: #adc6ff,
    on-palette-dark: #002e68,
    palette-container-dark: #004493,
    on-palette-container-dark: #edf0ff
  ),
  meet-red: (
    palette-light: #d32f2f,
    on-palette-light: #ffffff,
    palette-container-light: #ffedeb,
    on-palette-container-light: #410003,
    palette-dark: #ffb3ac,
    on-palette-dark: #680008,
    palette-container-dark: #930010,
    on-palette-container-dark: #ffedeb
  )
);

// Mixins for Themes
@mixin _generate-extended-light-theme($palette-name) {
  --sys-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), palette-light)};
  --sys-on-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), on-palette-light)};
  --sys-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), palette-container-light)};
  --sys-on-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), on-palette-container-light)};
}

@mixin _generate-extended-dark-theme($palette-name) {
  --sys-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), palette-dark)};
  --sys-on-#{$palette-name}: #{map.get(map.get($extended-palettes, $palette-name), on-palette-dark)};
  --sys-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), palette-container-dark)};
  --sys-on-#{$palette-name}-container: #{map.get(map.get($extended-palettes, $palette-name), on-palette-container-dark)};
}

// Generate Themes for All Palettes
@mixin extended-light-theme {
  @each $palette-name, $seed-color in $extended-palette-seed-colors {
    @include _generate-extended-light-theme($palette-name);
  }
}

@mixin extended-dark-theme {
  @each $palette-name, $seed-color in $extended-palette-seed-colors {
    @include _generate-extended-dark-theme($palette-name);
  }
}
